import React from "react";
import { Text, StyleSheet } from "react-native";
import { typography, spacing } from "../../constants/theme";

export const AppTitle = () => (
  <Text style={[typography.appName, styles.title]}>squeedo</Text>
);

const styles = StyleSheet.create({
  title: {
    marginTop: spacing.large,
    marginBottom: spacing.xl,
  },
}); 