import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AuthProvider, useAuth } from './src/context/AuthContext';
import { LoginScreen } from './src/screens/LoginScreen';
import { Timer } from './src/components/timer/Timer';
import { View, Text, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import { colors, spacing, typography } from './src/constants/theme';
import { useNavigation } from '@react-navigation/native';

const Stack = createNativeStackNavigator();

function AppHeader() {
  const { user, logout } = useAuth();
  const navigation = useNavigation();

  return (
    <View style={styles.header}>
      <View style={styles.spacer} />
      {user ? (
        <TouchableOpacity onPress={logout} style={styles.authButton}>
          <Text style={styles.buttonText}>Sign Out</Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity 
          onPress={() => navigation.navigate('Login' as never)} 
          style={styles.authButton}
        >
          <Text style={styles.buttonText}>Sign In</Text>
        </TouchableOpacity>
      )}
    </View>
  );
}

function MainScreen() {
  return (
    <SafeAreaView style={styles.container}>
      <AppHeader />
      <Timer />
    </SafeAreaView>
  );
}

function Navigation() {
  const { loading } = useAuth();
  
  if (loading) {
    return (
      <View style={styles.loading}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <Stack.Navigator 
      screenOptions={{ 
        headerShown: false,
        presentation: 'modal'
      }}
    >
      <Stack.Screen 
        name="Main" 
        component={MainScreen}
        options={{
          title: 'squeedo'
        }}
      />
      <Stack.Screen 
        name="Login" 
        component={LoginScreen}
        options={{
          title: 'squeedo'
        }}
      />
    </Stack.Navigator>
  );
}

export default function App() {
  return (
    <NavigationContainer>
      <AuthProvider>
        <Navigation />
      </AuthProvider>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: spacing.large,
    paddingVertical: spacing.medium,
    backgroundColor: colors.background,
  },
  authButton: {
    paddingVertical: spacing.small,
    paddingHorizontal: spacing.medium,
    borderRadius: 8,
    backgroundColor: colors.inactive,
  },
  buttonText: {
    ...typography.buttonText,
    color: colors.text,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  spacer: {
    width: 80, // Approximately the width of the auth button for balance
  },
});
