import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { TimerDisplay } from "./TimerDisplay";
import { TimerControls } from "./TimerControls";
import { DurationSelector } from "./DurationSelector";
import { AppTitle } from "../common/AppTitle";
import { useTimer } from "../../hooks/useTimer";
import { colors, spacing, typography } from "../../constants/theme";
import { TimerMode } from "../../types/timer";

export const Timer: React.FC = () => {
  const { timerState, toggleTimer, resetTimer, switchMode, setDuration } =
    useTimer();

  const getModeColor = (mode: TimerMode) =>
    mode === "work" ? colors.focus : colors.break;

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <AppTitle />
      <View style={styles.timerSection}>
        <TimerDisplay seconds={timerState.timeLeft} mode={timerState.mode} />
        {timerState.mode === "work" && (
          <DurationSelector
            selectedDuration={timerState.selectedDuration}
            onSelectDuration={setDuration}
            isActive={timerState.isActive}
          />
        )}
        <Text
          style={[
            typography.title,
            styles.modeText,
            { color: getModeColor(timerState.mode) },
          ]}
        >
          {timerState.mode === "work" ? "focus time" : "break time"}
        </Text>
        <View style={styles.controlsSection}>
          <TimerControls
            isActive={timerState.isActive}
            onToggle={toggleTimer}
            onReset={resetTimer}
            onModeSwitch={switchMode}
            mode={timerState.mode}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: spacing.large,
  },
  timerSection: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    gap: spacing.medium,
  },
  controlsSection: {
    marginTop: spacing.medium,
  },
  modeText: {
    textTransform: "lowercase",
    opacity: 0.9,
  },
});
