import { StyleSheet } from 'react-native';

export const colors = {
  primary: '#1B1B1B',
  secondary: '#2A2A2A',
  background: '#FFFFFF',
  surface: '#FAFAFA',
  text: '#1B1B1B',
  textLight: '#757575',
  focus: '#006D77',
  break: '#E29578',
  active: '#006D77',
  inactive: '#F5F5F5',
  success: '#83C5BE',
  error: '#E29578',
};

export const spacing = {
  xs: 4,
  small: 8,
  medium: 16,
  large: 24,
  xl: 32,
  xxl: 48,
};

export const typography = StyleSheet.create({
  appName: {
    fontSize: 24,
    fontWeight: '400',
    color: colors.textLight,
    letterSpacing: 6,
    textTransform: 'lowercase',
  },
  title: {
    fontSize: 16,
    fontWeight: '400',
    color: colors.textLight,
    letterSpacing: 2,
  },
  timer: {
    fontSize: 96,
    fontWeight: '200',
    color: colors.focus,
    letterSpacing: -2,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '400',
    letterSpacing: 1,
    textTransform: 'lowercase',
  },
});

export const shadows = {
  small: {
    shadowColor: colors.primary,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.03,
    shadowRadius: 2,
    elevation: 1,
  },
}; 