import React from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { colors, spacing, typography } from "../../constants/theme";
import { TimerMode } from "../../types/timer";

interface TimerControlsProps {
  isActive: boolean;
  onToggle: () => void;
  onReset: () => void;
  onModeSwitch: () => void;
  mode: TimerMode;
}

export const TimerControls: React.FC<TimerControlsProps> = React.memo(
  ({ isActive, onToggle, onReset, onModeSwitch, mode }) => {
    const buttonColor = mode === "work" ? colors.focus : colors.break;

    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={[
            styles.mainButton,
            {
              borderColor: buttonColor,
              backgroundColor: isActive ? buttonColor : "transparent",
            },
          ]}
          onPress={onToggle}
        >
          <Text
            style={[
              typography.buttonText,
              { color: isActive ? colors.background : buttonColor },
            ]}
          >
            {isActive ? "pause" : "start"}
          </Text>
        </TouchableOpacity>

        <View style={styles.secondaryButtonsContainer}>
          <TouchableOpacity style={styles.secondaryButton} onPress={onReset}>
            <Text style={[typography.buttonText, { color: colors.textLight }]}>
              reset
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.secondaryButton}
            onPress={onModeSwitch}
          >
            <Text style={[typography.buttonText, { color: colors.textLight }]}>
              switch to {mode === "work" ? "break" : "focus"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    gap: spacing.xl,
  },
  mainButton: {
    paddingHorizontal: spacing.xxl,
    paddingVertical: spacing.medium,
    borderWidth: 1.5,
    borderRadius: 30,
    minWidth: 140,
    alignItems: "center",
  },
  secondaryButtonsContainer: {
    flexDirection: "row",
    gap: spacing.large,
    justifyContent: "center",
  },
  secondaryButton: {
    paddingHorizontal: spacing.medium,
    paddingVertical: spacing.small,
  },
});
