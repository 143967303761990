import React from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { colors, spacing, typography } from "../../constants/theme";
import type { Duration } from "../../types/timer";

interface DurationSelectorProps {
  selectedDuration: Duration;
  onSelectDuration: (duration: Duration) => void;
  isActive: boolean;
}

const durations: Duration[] = [15, 25, 30];

export const DurationSelector: React.FC<DurationSelectorProps> = ({
  selectedDuration,
  onSelectDuration,
  isActive,
}) => {
  return (
    <View style={styles.container}>
      {durations.map((duration) => (
        <TouchableOpacity
          key={duration}
          style={[
            styles.chip,
            selectedDuration === duration && styles.selectedChip,
            isActive && { opacity: 0.5 },
          ]}
          onPress={() => onSelectDuration(duration)}
          disabled={isActive}
        >
          <Text
            style={[
              typography.buttonText,
              styles.chipText,
              selectedDuration === duration && styles.selectedChipText,
            ]}
          >
            {duration}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: spacing.medium,
  },
  chip: {
    paddingVertical: spacing.small,
    paddingHorizontal: spacing.large,
    borderRadius: 50,
    backgroundColor: colors.inactive,
    minWidth: 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedChip: {
    backgroundColor: colors.focus,
  },
  chipText: {
    color: colors.textLight,
    fontSize: 15,
    fontWeight: '500',
  },
  selectedChipText: {
    color: colors.background,
  },
});
