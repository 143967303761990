import React from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { colors } from "../../constants/theme";
import { TimerMode } from "../../types/timer";

interface TimerDisplayProps {
  seconds: number;
  mode: TimerMode;
}

export const TimerDisplay: React.FC<TimerDisplayProps> = ({ seconds, mode }) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const timerColor = mode === "work" ? colors.focus : colors.break;

  return (
    <View style={styles.container}>
      <Text style={[styles.time, { color: timerColor }]}>
        {`${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  time: {
    fontSize: 68,
    fontWeight: '400',
    letterSpacing: 0.5,
    fontFamily: Platform.OS === 'ios' ? 'Helvetica Neue' : 'sans-serif',
  }
});
