import React, { useState } from 'react';
import { View, TextInput, TouchableOpacity, Text, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';
import { useAuth } from '../context/AuthContext';
import { colors, spacing, typography, shadows } from '../constants/theme';
import { useNavigation } from '@react-navigation/native';
import DateTimePicker, { DateTimePickerEvent } from '@react-native-community/datetimepicker';
import { AppTitle } from '../components/common/AppTitle';
import { GoogleLogo } from '../components/common/GoogleLogo';

interface RegistrationData {
  firstName: string;
  lastName: string;
  dob: string;
}

export const LoginScreen = () => {
  const navigation = useNavigation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const { signIn, register, signInWithGoogle } = useAuth();

  const handleDateChange = (event: DateTimePickerEvent, selectedDate?: Date) => {
    setShowDatePicker(false);
    if (selectedDate && event.type !== 'dismissed') {
      setDate(selectedDate);
      setDob(selectedDate.toISOString().split('T')[0]);
    }
  };

  const handleDonePress = () => {
    const event: DateTimePickerEvent = {
      type: 'set',
      nativeEvent: {
        timestamp: date.getTime(),
      },
    };
    handleDateChange(event, date);
  };

  const handleSubmit = async () => {
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }

    if (isRegistering && (!firstName || !lastName || !dob)) {
      setError('Please fill in all fields');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      if (isRegistering) {
        await register(email, password, {
          firstName,
          lastName,
          dob,
        });
      } else {
        await signIn(email, password);
      }
      navigation.goBack();
    } catch (error: any) {
      setError(error.message.includes('auth/') 
        ? isRegistering 
          ? 'Email already in use or invalid'
          : 'Invalid email or password'
        : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMode = () => {
    setIsRegistering(!isRegistering);
    setError('');
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      setError('');
      await signInWithGoogle();
      navigation.goBack();
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      if (error.code !== 'auth/popup-closed-by-user') {
        setError('Google sign-in failed. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderDateInput = () => {
    if (Platform.OS === 'web') {
      return (
        <View style={styles.inputContainer}>
          <TouchableOpacity 
            style={styles.input}
            onPress={(e) => {
              const target = e.currentTarget as unknown as HTMLElement;
              const rect = target.getBoundingClientRect();
              const input = document.createElement('input');
              input.type = 'date';
              input.style.opacity = '0';
              input.style.position = 'fixed';
              input.style.left = `${rect.left}px`;
              input.style.top = `${rect.bottom + 2}px`;
              input.max = new Date().toISOString().split('T')[0];
              input.min = '1900-01-01';
              input.value = dob;
              
              input.onchange = (e) => {
                const target = e.target as HTMLInputElement;
                setDob(target.value);
                document.body.removeChild(input);
              };
              
              input.onblur = () => {
                document.body.removeChild(input);
              };
              
              document.body.appendChild(input);
              setTimeout(() => {
                input.showPicker ? input.showPicker() : input.click();
              }, 0);
            }}
          >
            <Text style={[styles.dateText, !dob && styles.placeholderText]}>
              {dob ? new Date(dob).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }) : 'Date of Birth'}
            </Text>
          </TouchableOpacity>
        </View>
      );
    }

    return (
      <View style={styles.inputContainer}>
        <TouchableOpacity 
          onPress={() => setShowDatePicker(true)}
          style={styles.input}
        >
          <Text style={[styles.dateText, !dob && styles.placeholderText]}>
            {dob ? new Date(dob).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }) : 'Date of Birth'}
          </Text>
        </TouchableOpacity>

        {Platform.OS === 'ios' ? (
          showDatePicker && (
            <View style={styles.iosPickerContainer}>
              <View style={styles.iosPickerHeader}>
                <TouchableOpacity 
                  onPress={() => setShowDatePicker(false)}
                  style={styles.iosPickerButton}
                >
                  <Text style={styles.iosPickerButtonText}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                  onPress={handleDonePress}
                  style={styles.iosPickerButton}
                >
                  <Text style={[styles.iosPickerButtonText, { color: colors.focus }]}>Done</Text>
                </TouchableOpacity>
              </View>
              <DateTimePicker
                value={date}
                mode="date"
                display="spinner"
                onChange={(event, selectedDate) => {
                  if (selectedDate) setDate(selectedDate);
                }}
                maximumDate={new Date()}
                minimumDate={new Date(1900, 0, 1)}
                style={styles.iosPicker}
              />
            </View>
          )
        ) : (
          showDatePicker && (
            <DateTimePicker
              value={date}
              mode="date"
              display="default"
              onChange={handleDateChange}
              maximumDate={new Date()}
              minimumDate={new Date(1900, 0, 1)}
            />
          )
        )}
      </View>
    );
  };

  return (
    <KeyboardAvoidingView 
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
    >
      <View style={styles.header}>
        <TouchableOpacity 
          onPress={() => navigation.goBack()}
          style={styles.closeButton}
        >
          <Text style={styles.closeButtonText}>×</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.loginContainer}>
        <AppTitle />
        <View style={styles.formContainer}>
          {error ? <Text style={styles.error}>{error}</Text> : null}

          <TouchableOpacity 
            style={[styles.button, styles.googleButton]}
            onPress={handleGoogleSignIn}
            disabled={isLoading}
          >
            <View style={styles.googleButtonContent}>
              <GoogleLogo />
              <Text style={[styles.buttonText, styles.googleButtonText]}>
                Continue with Google
              </Text>
            </View>
          </TouchableOpacity>

          <View style={styles.divider}>
            <View style={styles.dividerLine} />
            <Text style={styles.dividerText}>or</Text>
            <View style={styles.dividerLine} />
          </View>
          
          {isRegistering && (
            <>
              <View style={styles.inputContainer}>
                <TextInput
                  style={styles.input}
                  placeholder="First Name"
                  placeholderTextColor={colors.textLight}
                  value={firstName}
                  onChangeText={setFirstName}
                  autoCapitalize="words"
                />
              </View>

              <View style={styles.inputContainer}>
                <TextInput
                  style={styles.input}
                  placeholder="Last Name"
                  placeholderTextColor={colors.textLight}
                  value={lastName}
                  onChangeText={setLastName}
                  autoCapitalize="words"
                />
              </View>

              {renderDateInput()}
            </>
          )}

          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              placeholder="Email"
              placeholderTextColor={colors.textLight}
              value={email}
              onChangeText={setEmail}
              autoCapitalize="none"
              keyboardType="email-address"
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              placeholder="Password"
              placeholderTextColor={colors.textLight}
              value={password}
              onChangeText={setPassword}
              secureTextEntry
            />
          </View>

          <TouchableOpacity 
            style={[styles.button, styles.primaryButton, isLoading && styles.buttonDisabled]}
            onPress={handleSubmit}
            activeOpacity={0.8}
            disabled={isLoading}
          >
            <Text style={[styles.buttonText, styles.primaryButtonText]}>
              {isLoading 
                ? (isRegistering ? 'Creating Account...' : 'Signing In...') 
                : (isRegistering ? 'Create Account' : 'Sign In')}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity 
            onPress={toggleMode}
            style={styles.toggleButton}
          >
            <Text style={styles.toggleText}>
              {isRegistering 
                ? 'Already have an account? Sign in' 
                : 'Need an account? Create one'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  loginContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing.large,
  },
  title: {
    fontSize: 28,
    marginBottom: spacing.xxl,
    letterSpacing: 6,
    textTransform: 'lowercase',
    color: colors.primary,
  },
  formContainer: {
    width: '100%',
    maxWidth: 360,
    padding: spacing.xl,
    borderRadius: 12,
    backgroundColor: colors.surface,
    ...shadows.small,
  },
  inputContainer: {
    marginBottom: spacing.medium,
  },
  input: {
    height: 48,
    borderWidth: 1,
    borderColor: colors.inactive,
    borderRadius: 8,
    paddingHorizontal: spacing.medium,
    fontSize: 15,
    color: colors.text,
    backgroundColor: colors.background,
  },
  button: {
    height: 48,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing.small,
  },
  primaryButton: {
    backgroundColor: colors.focus,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '500',
    letterSpacing: 0.5,
  },
  primaryButtonText: {
    color: colors.surface,
  },
  googleButton: {
    backgroundColor: colors.background,
    borderWidth: 1,
    borderColor: colors.inactive,
  },
  googleButtonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.medium,
  },
  googleButtonText: {
    color: colors.text,
  },
  error: {
    color: colors.error,
    textAlign: 'center',
    marginBottom: spacing.medium,
    fontSize: 14,
  },
  header: {
    position: 'absolute',
    top: spacing.large,
    right: spacing.large,
    zIndex: 1,
  },
  closeButton: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: colors.inactive,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButtonText: {
    fontSize: 20,
    color: colors.text,
    lineHeight: 20,
  },
  buttonDisabled: {
    opacity: 0.7,
  },
  toggleButton: {
    marginTop: spacing.medium,
    alignItems: 'center',
  },
  toggleText: {
    color: colors.focus,
    fontSize: 14,
  },
  divider: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: spacing.medium,
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: colors.inactive,
  },
  dividerText: {
    color: colors.textLight,
    paddingHorizontal: spacing.medium,
    fontSize: 14,
  },
  dateText: {
    fontSize: 15,
    color: colors.text,
    paddingVertical: 12,
  },
  placeholderText: {
    color: colors.textLight,
  },
  iosPickerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.background,
    borderTopWidth: 1,
    borderColor: colors.inactive,
  },
  iosPickerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing.medium,
    borderBottomWidth: 1,
    borderColor: colors.inactive,
  },
  iosPickerButton: {
    padding: spacing.small,
  },
  iosPickerButtonText: {
    fontSize: 16,
    color: colors.text,
  },
  iosPicker: {
    height: 200,
  },
}); 