import { useState, useCallback, useEffect, useRef } from 'react';
import { TimerMode, TimerState, Duration } from '../types/timer';

const BREAK_TIME = 5 * 60; // 5 minutes in seconds

export const useTimer = () => {
  const [timerState, setTimerState] = useState<TimerState>({
    timeLeft: 25 * 60,
    isActive: false,
    mode: 'work',
    selectedDuration: 25,
  });
  
  const intervalRef = useRef<NodeJS.Timeout>();

  const setDuration = useCallback((duration: Duration) => {
    setTimerState(prev => ({
      ...prev,
      timeLeft: duration * 60,
      selectedDuration: duration,
      isActive: false,
    }));
  }, []);

  const toggleTimer = useCallback(() => {
    setTimerState(prev => ({ ...prev, isActive: !prev.isActive }));
  }, []);

  const resetTimer = useCallback(() => {
    setTimerState(prev => ({
      ...prev,
      timeLeft: prev.mode === 'work' ? prev.selectedDuration * 60 : BREAK_TIME,
      isActive: false,
    }));
  }, []);

  const switchMode = useCallback(() => {
    setTimerState(prev => ({
      mode: prev.mode === 'work' ? 'break' : 'work',
      timeLeft: prev.mode === 'work' ? BREAK_TIME : prev.selectedDuration * 60,
      isActive: false,
      selectedDuration: prev.selectedDuration,
    }));
  }, []);

  useEffect(() => {
    if (timerState.isActive) {
      intervalRef.current = setInterval(() => {
        setTimerState(prev => {
          if (prev.timeLeft <= 0) {
            clearInterval(intervalRef.current);
            // Automatically switch to break mode and start timer
            return {
              mode: prev.mode === 'work' ? 'break' : 'work',
              timeLeft: prev.mode === 'work' ? BREAK_TIME : prev.selectedDuration * 60,
              isActive: true, // Keep timer running
              selectedDuration: prev.selectedDuration,
            };
          }
          return { ...prev, timeLeft: prev.timeLeft - 1 };
        });
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [timerState.isActive]);

  return { timerState, toggleTimer, resetTimer, switchMode, setDuration };
}; 