import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCSGbZUbxE8_Dcm_rGxzCjge8qCXcMMboQ",
  authDomain: "squeedo-staging.firebaseapp.com",
  projectId: "squeedo-staging",
  storageBucket: "squeedo-staging.appspot.com",
  messagingSenderId: "511301414885",
  appId: "1:511301414885:web:fdc38a31e1cfcaf0c9890c",
  measurementId: "G-Y2W5QDRR4T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

// Configure Google Provider with additional scopes
export const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email'); 